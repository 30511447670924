import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
import GerarOrdemPix from "../pages/GerarOrdemPix";
import OrdensPix from "../pages/OrdensPix";
import Usuarios from "../pages/Usuarios";
import NovoUsuario from "../pages/Usuarios/NovoUsuario";
import EditarUsuario from "../pages/Usuarios/EditarUsuario";
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/gerar-ordem-pix", component: <GerarOrdemPix /> },
  { path: "/ordens-pix", component: <OrdensPix /> },
  { path: "/usuarios", component: <Usuarios /> },
  { path: "/novo-usuario", component: <NovoUsuario /> },
  { path: "/editar-usuario/:user_id", component: <EditarUsuario /> },
  { path: "/index", component: <DashboardEcommerce /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/gerar-ordem-pix" />,
  },
  { path: "*", component: <Navigate to="/gerar-ordem-pix" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  { path: "/erro-404", component: <Basic404 /> },

];

export { authProtectedRoutes, publicRoutes };