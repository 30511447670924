import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";

const ModalApagar = ({ modal, setModal, setRefresh, ordem }) => {
  const toggle = () => {
    setRefresh(true);
    setModal(!modal);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Pagar Ordem # {ordem?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="-flex justify-content-center mt-3">
            <Col md="12" className="d-flex justify-content-center">
              {
                <QRCode
                  value={ordem?.qrcode}
                  size={220}
                  style={{ backgroundColor: "white" }}
                  className="p-2"
                />
              }
            </Col>
            <div className="text-center mt-4">
              Realize o pagamento com o QRCode e feche a janela para verificar a
              conclusão do pagamento.
            </div>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-primary fw-medium"
              onClick={() => {
                navigator.clipboard.writeText(ordem?.qrcode);
                toast.success("QRCode copiado com sucesso!");
              }}
            >
              <FeatherIcon icon="copy" size="14" /> Pix Copia e Cola
            </Link>
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagar;
