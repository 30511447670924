import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLoggedinUser } from "../helpers/api_helper";

const Navdata = () => {
  const history = useNavigate();
  const [userData, setUserData] = useState({});
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isGerarQrCode, setIsGerarQrCode] = useState("GerarOrdemPix");
  const [isOrdensPix, setIsOrdensPix] = useState("OrdensPix");
  const [isUsuarios, setIsUsuarios] = useState("Usuarios");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    const getData = async () => {
      const user = await getLoggedinUser();
      setUserData(user);
    };
    getData();
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "GerarOrdemPix") {
      setIsGerarQrCode(false);
    }
    if (iscurrentState !== "OrdensPix") {
      setIsOrdensPix(false);
    }
    if (iscurrentState !== "Usuarios") {
      setIsUsuarios(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isGerarQrCode,
    isOrdensPix,
    isUsuarios,
  ]);

  const dashboardAdmin = {
    id: "dashboard",
    label: "Dashboards",
    icon: "mdi mdi-speedometer",
    link: "/dashboard",
    stateVariables: isDashboard,
    click: function (e) {
      e.preventDefault();
      setIsDashboard(!isDashboard);
      setIscurrentState("Dashboard");
      updateIconSidebar(e);
    },
  };

  const ordensPix = [
    {
      id: "gerar-ordem-pix",
      label: "Gerar Ordem Pix",
      icon: "mdi mdi-qrcode",
      link: "/gerar-ordem-pix",
      stateVariables: isGerarQrCode,
      click: function (e) {
        e.preventDefault();
        setIsGerarQrCode(!isGerarQrCode);
        setIscurrentState("GerarOrdemPix");
        updateIconSidebar(e);
      },
    },
    {
      id: "ordens-pix",
      label: "Ordens Pix",
      icon: "mdi mdi-currency-usd",
      link: "/ordens-pix",
      stateVariables: isOrdensPix,
      click: function (e) {
        e.preventDefault();
        setIsOrdensPix(!isOrdensPix);
        setIscurrentState("OrdensPix");
        updateIconSidebar(e);
      },
    },
  ];

  const usuarios = {
    id: "usuarios",
    label: "Usuários",
    icon: "mdi mdi-account",
    link: "/usuarios",
    stateVariables: isOrdensPix,
    click: function (e) {
      e.preventDefault();
      setIsUsuarios(!isUsuarios);
      setIscurrentState("Usuarios");
      updateIconSidebar(e);
    },
  };

  const menuItems =
    userData?.role === "admin"
      ? [
          {
            label: "Menu",
            isHeader: true,
          },
          dashboardAdmin,
          ...ordensPix,
          usuarios,
        ]
      : userData?.role === "lojista"
      ? [
          {
            label: "Menu",
            isHeader: true,
          },
          dashboardAdmin,
          ...ordensPix,
          usuarios,
        ]
      : userData?.role === "atendente"
      ? [
          {
            label: "Menu",
            isHeader: true,
          },
          ...ordensPix,
        ]
      : [
          {
            label: "Menu",
            isHeader: true,
          },
          // dashboardAdmin,
          ...ordensPix,
          usuarios,
        ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
