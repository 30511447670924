import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import logoLight from "../../assets/images/logo-light.png";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [lembrar, setLembrar] = useState(false);

  document.title = "Login | Nautt Finance";

  const esquecerLembrarSenha = useCallback((lembrarSenha, user, pass) => {
    if (!lembrarSenha) {
      localStorage.removeItem("dadosLogin");
    } else {
      const passBase64 = btoa(pass);
      localStorage.setItem(
        "dadosLogin",
        JSON.stringify({ username: user, password: passBase64 })
      );
    }

    setLembrar(lembrarSenha);
    console.log(lembrarSenha);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await apiClient.postLogin({
      username,
      password,
    });
    console.log(response);
    if (response.sucesso && response.dados) {
      localStorage.setItem("authUser", JSON.stringify(response.dados));
      localStorage.setItem("lastLogin", Date.now());
      setTimeout(() => {
        window.location.href = "/principal";
      }, 200);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }

    setLoading(false);
  };

  useEffect(() => {
    const dadosLogin = JSON.parse(localStorage.getItem("dadosLogin"));
    if (
      dadosLogin &&
      !lembrar &&
      dadosLogin.username !== "" &&
      dadosLogin.passBase64 !== ""
    ) {
      setUsername(dadosLogin.username);
      setPassword(atob(dadosLogin.password));
      setLembrar(true);
    }
  }, [lembrar]);
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="53" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">
                    A sua porta de entrada para o futuro financeiro.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Bem-Vindo!</h5>
                      <p className="text-muted">Faça seu login para acessar</p>
                    </div>
                    <div className="p-2 mt-4">
                      <div className="mb-3">
                        <Label htmlFor="username" className="form-label">
                          Usuário
                        </Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Entre seu usuário"
                          type="text"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Esqueceu sua senha?
                          </Link>
                        </div>
                        <Label className="form-label" htmlFor="password-input">
                          Senha
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            name="password"
                            value={password}
                            type={showPassword ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Entre sua senha"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                            onClick={() => setShowPassword(!showPassword)}
                            type="button"
                            id="password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            checked={lembrar}
                            onChange={(e) =>
                              esquecerLembrarSenha(
                                e.target.checked,
                                username,
                                password
                              )
                            }
                            disabled={username === "" || password === ""}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Lembre-se de mim
                          </Label>
                        </div>
                      </div>

                      <div className="mt-4">
                        <Button
                          color="success"
                          disabled={loading}
                          className="btn btn-success w-100"
                          onClick={(e) => handleSubmit(e)}
                        >
                          {loading ? (
                            <Spinner size="sm" className="me-2" />
                          ) : null}
                          Logar
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Não tem uma conta ?{" "}
                    <Link
                      to="https://wa.me/message/ZZWGGICWNKSPK1"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Fale conosco{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
